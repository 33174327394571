'use client';
import Image from 'next/image';
import { useRouter, useSearchParams } from 'next/navigation';
import { withReactSuspense } from '@/shared/appRouter/hocs/withReactSuspense';
import SearchIconImage from './icon_search_white.svg?url';

export const SearchBoxForBigBar: React.FC = withReactSuspense(() => {
  const params = useSearchParams();
  const router = useRouter();

  return (
    <form
      action=""
      method="get"
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const searchQuery = form.get('searchQuery');
        if (!searchQuery) {
          return;
        }
        router.push(`/guide/search?q=${searchQuery}`);
      }}
    >
      <div className="tw-grid tw-h-[3.5rem] tw-grid-cols-[1fr,4rem] tw-overflow-hidden tw-rounded-8 tw-bg-base-100">
        <div>
          <input
            type="text"
            name="searchQuery"
            placeholder="例) 再生、パスワード、解約方法"
            defaultValue={params?.get('q') ?? ''}
            className="tw-size-full tw-border-none tw-px-[1rem] tw-outline-none"
          />
        </div>
        <div>
          <button
            className="tw-relative tw-h-full tw-w-[4rem] tw-overflow-hidden tw-bg-accent-primary"
            type="submit"
          >
            <Image
              src={SearchIconImage}
              alt=""
              className="tw-absolute tw-inset-0 tw-m-auto tw-size-[1.5rem]"
            />
          </button>
        </div>
      </div>
    </form>
  );
});
